<template>

    <div class="h-auto rounded-lg flex flex-col overflow-hidden bg-filters relative">
        <loader :loading="loading" />
        <div class="h-auto py-2 flex flex-row justify-between items-center px-2 mb-auto">
            <span class="text-font-gray font-semibold text-xs">Billing</span>
        </div>

        <div class="h-auto w-full flex flex-row px-2">
            <div class="h-full w-full flex flex-row justify-between items-center">
                <span class="text-xxs font-semibold text-font-gray">Invoced</span>
                <span class="text-xs font-semibold text-white">
                    {{ billing_data.invoiced | reduceBigNumbers(2) }} €
                </span>
            </div>
        </div>

        <div class="h-auto w-full mr-auto flex flex-row justify-between items-center px-2 mt-2">
            <span class="text-xxs text-font-gray mr-4">Collected</span>
            <span class="text-xxs font-semibold text-font-gray">{{ billing_data.collected | reduceBigNumbers(2) }} €</span>
        </div>

        <div class="h-auto w-full mr-auto flex flex-row justify-between items-center px-2 mt-2">
            <span class="text-xxs text-font-gray mr-4">Not Collected</span>
            <span class="text-xxs font-semibold text-font-gray">{{ billing_data.not_collected | reduceBigNumbers(2) }} €</span>
        </div>

        <div class="h-auto w-full mr-auto flex flex-row justify-between items-center px-2 mt-2 mb-2">
            <span class="text-xxs font-semibold text-font-gray mr-4">Unbilled</span>
            <span class="text-xs font-semibold text-white">{{ billing_data.unbilled | reduceBigNumbers(2) }} €</span>
        </div>

    </div>

</template>

<script>

    import { state } from '@/store';

    export default {
        data() {
            return {
                loading: false,
                billing_data: false
            }
        },
        methods:{
            preload() {
                this.loading = true
            },
            load() {
                this.axios.get('billing/billing', {params: this.params}).then(response => {
                    this.loading = false;
                    this.billing_data = response.data.data;
                })
            }
        },
        computed: {
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{sectorAmbiciona: this.$route.params.catId} }
                }
                return params
            }
        },
        watch: {
            params() { this.load() }
        },
        mounted() {
            this.preload();
            if(this.params) {
                this.load();
            }
        }
    }

</script>